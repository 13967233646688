<template>
	<b-form-group :label="item.title" class="bold-label" :description="item.helpText">
		<b-row>
			<b-col sm="12">
				<div class="uploaded-files">
					<span class="uploaded-nofiles" v-if="uploadedItems.length === 0">No files uploaded</span>
					<div class="evidence-item" :key="idx" v-for="(e, idx) of this.uploadedItems">
						<b-dropdown size="xs" variant="outline-dark" class="mx-1 evidence-dropdown" right :text="e.name" :title="e.name">
							<b-dropdown-item :href="evidenceURL(e)" target="_blank">Open</b-dropdown-item>
							<b-dropdown-item @click="deleteFile(e)" class="dropdown-danger">Delete</b-dropdown-item>
						</b-dropdown>
					</div>
				</div>
			</b-col>
		</b-row>

		<b-row v-if="uploading">
			<b-col sm="12">
				<b-progress :value="uploadPercentage" :max="100" variant="info" show-progress animated />
			</b-col>
		</b-row>

		<b-row>
			<b-col sm="12">
				<b-alert class="w-100" show variant="info" v-if="inputType === 'myFiles' && !unassignedFiles.length">You can only attach unassigned files from the My Files section</b-alert>
			</b-col>
			<b-col sm="8" md="5" lg="8">
				<b-form-select @change="attachUnassignedFile" v-if="inputType === 'myFiles'" v-model="selectedUnassignedFile">
					<option :value="null">-- Select Evidence from My Files --</option>
					<option v-for="file in unassignedFiles" v-bind:key="file.id" :value="file">{{ file.filename }}</option>
				</b-form-select>
				
				<b-form-file v-else @input="uploadFile" ref="fileInput" v-model="selectedFile" />
			</b-col>

			<b-col sm="4" md="7" lg="4">
				<b-button variant="dark" class="ovr-button__inverted" style="justify-content: center;" :block="true" @click="handleChangeInputType">
					{{ inputType === 'pc' ? 'Browse from My Files' : 'Browse from my computer' }}
				</b-button>
			</b-col>

		</b-row>

	</b-form-group>
</template>

<script>
import EventBus from '../../event-bus';
import FormItem from "./FormItem";

export default {
	name: "Upload",
	extends: FormItem,
	data: function () {
		return {
			selectedFile: null,
			uploadedItems: [],
			uploading: false,
			uploadPercentage: 0,
			unassignedFiles: [],
			selectedUnassignedFile: null,

			inputType: 'pc',
		}
	},
	methods: {
		handleChangeInputType() {
			const { inputType } = this
			this.inputType = inputType === 'pc' ? 'myFiles' : 'pc'
		},
		deleteFile(e) {
			this.uploadedItems = this.uploadedItems.filter((val) => val.id !== e.id);
		},
		uploadFile: function () {
			if (this.selectedFile.size > 10 * 1024 * 1024) {
				this.$error('File Size', 'The file cannot exceed 10MB');
				return;
			}

			this.uploading = true;
			this.uploadPercentage = 0;

			let fileName = this.selectedFile.name;
			let reader = new FileReader();
			let vueThis = this;
			reader.onload = (e) => {
				let contentType = vueThis.selectedFile.type;
				let data = e.target.result;
				this.$http.post(`file/upload?filename=${encodeURIComponent(fileName)}`, data, {
					progress(e) {
						if (e.lengthComputable) {
							vueThis.uploadPercentage = e.loaded / e.total * 100;
						}
					},
					headers: {
						'Content-Type': contentType
					}
				}).then((resp) => {
					EventBus.$emit('file-uploaded');
					this.uploadedItems.push({
						id: resp.body.storageId,
						name: fileName,
					});
					this.$refs.fileInput.reset();
				}).finally(() => {
					this.uploading = false;
				}).catch((resp) => {
					this.$httpError('Failed to upload file', resp);
					this.uploading = false;
				});
			};

			reader.readAsArrayBuffer(this.selectedFile);
		},
		evidenceURL: function (e) {
			return process.env.VUE_STORAGE_API+'cabinet/?id=' + e.id
		},
		updateItemValue: function () {
			this.item.value = this.uploadedItems.map(v => v);
		},
		downloadUnassignedFiles: function () {
			this.$http.get('file/unassigned').then(resp => {
				this.unassignedFiles = resp.body;
			});
		},
		attachUnassignedFile: function () {
			let storageId = this.selectedUnassignedFile?.id;
			this.uploadedItems.push({
				id: storageId,
				name: this.selectedUnassignedFile.filename,
			});
			this.unassignedFiles = this.unassignedFiles.filter(item => item.id !== storageId);
			this.selectedUnassignedFile = null;
		}
	},
	watch: {
		uploadedItems: function () {
			this.updateItemValue();
		}
	},
	mounted() {
		if (this.item.value && this.item.value.length > 0) {
			this.uploadedItems = this.item.value.map(item => {
				return { id: item.id, name: item.name };
			});
		}
		this.downloadUnassignedFiles();
	}
}
</script>

<style scoped lang="scss">
.bold-label {
	font-weight: 600;
	margin-bottom: 40px;
}

.uploaded-files {
	border: 1px dashed #BBB;
    padding: 32px 16px;
    margin: auto;
    display: flex;
    justify-content: center;
	flex-wrap: wrap;
	gap: 4px;
}

.row {
	margin-bottom: 20px;
}

span.uploaded-nofiles {
	font-weight: 600;
}

div.loader {
	margin-left: auto;
	margin-right: auto;
}

div.evidence-item {
	display: inline-block;
	max-width: 95%;
}

.evidence-dropdown {
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>